<template>
  <div>
    <div class="text-center display-1 font-weight-normal mb-6">
      Vamos começar preenchendo as informações básicas
    </div>
    <v-row
      id="importacao-titulo"
      class="mx-auto"
    >
      <v-col cols="12">
        <validation-provider
          v-slot="{ errors }"
          rules="required"
        >
          <v-text-field
            id="fs-titulo-importacao-indger"
            label="Título da importação"
            outlined
            :error-messages="errors"
            v-model="titulo"
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row
      id="importacao-descricao"
      class="mx-auto"
    >
      <v-col cols="12">
        <v-text-field
          id="fs-descricao-importacao-indger"
          label="Descrição (opcional)"
          outlined
          v-model="descricao"
        />
      </v-col>
    </v-row>
    <v-row
      id="importacao-empresa"
      class="mx-auto"
    >
      <v-col cols="12">
        <select-company
          id="fs-empresa-importacao-indger"
          :type="'outlined'"
          @companySelected="companySelected"
        />
      </v-col>
    </v-row>
    <v-row
      id="importacao-mes"
      class="mx-auto"
    >
      <v-col
        cols="12"
        id="fs-mes-dados-importacao-indger"
        v-if="companyId"
      >
        <input-month
          label="Mês dos dados"
          @monthSelected="monthSelected"
        />
      </v-col>
    </v-row>
    <v-row
      id="importacao-indger-versao"
      class="mx-auto"
      v-if="companyId"
    >
      <v-col cols="12">
        <validation-provider v-slot="{ errors }" rules="required|min_value:0">
          <v-text-field
            class="my-0"
            type="number"
            min="0"
            label="Versão do INDGER"
            v-model="indgerVersion"
            :value="indgerVersion"
            prepend-icon="mdi-numeric-1-box-multiple-outline"
            :error-messages="errors"
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row class="mx-auto">
      <v-col cols="12" v-if="mes">
        <v-alert
          dense 
          outlined 
          type="info" 
          class="mb-1 py-1 px-1"
        >
          Última versão de {{ data | parseToCustomDateFormat('MM/YYYY') }}: <strong> {{ lastIndgerVersion >= 0 ? `v${lastIndgerVersion}` : "nenhuma versão cadastrada" }} </strong>
        </v-alert>
        <v-alert
          dense 
          outlined 
          type="info" 
          class="mb-1 py-1 px-1"
        >
          <strong>Obs.:</strong> as validações de indicadores são executadas apenas na <strong>versão 0</strong> (versão de trabalho)
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import IndgerVersionsService from "@/services/IndgerVersionsService.js";

export default {
  name: 'ImportacoesIndgerFirstTabContent',
  components: {
    SelectCompany: () => import('@/components/general/SelectCompany.vue'),
    InputMonth: () => import('@/components/general/InputMonth.vue')
  },
  data() {
    return {
      titulo: null,
      descricao: null,
      companyId: null,
      mes: null,
      indgerVersion: 0,
      lastIndgerVersion: null,
    };
  },
  computed: {
    data() {
      return this.mes ? `${this.mes}-01` : null;
    }
  },
  watch: {
    titulo() {
      this.emitEventImportUpdated();
    },
    descricao() {
      this.emitEventImportUpdated();
    },
    companyId() {
      this.emitEventImportUpdated();
      this.getLastIndgerVersion(this.mes);
    },
    mes() {
      this.emitEventImportUpdated();
      this.getLastIndgerVersion(this.mes);
    },
    indgerVersion() {
      this.emitEventImportUpdated();
    }
  },
  methods: {
    companySelected(company) {
      this.companyId = company.id;
    },
    monthSelected(month) {
      this.mes = month;
    },
    indgerVersionSelected(indgerVersion) {
      this.indgerVersion = indgerVersion;
    },
    getLastIndgerVersion(dataMonth) {
      if (!dataMonth) {
        this.lastIndgerVersion = null;
        return;
      }

      IndgerVersionsService.getLastVersionByCompanyAndDataMonth(
        this.companyId,
        dataMonth
      )
        .then(res => {
          this.lastIndgerVersion = res?.version;
        })
        .catch(err => {
          console.error(err);
        });
    },
    emitEventImportUpdated() {
      this.$emit('import:updated', {
        titulo: this.titulo,
        descricao: this.descricao,
        companyId: this.companyId,
        mes: this.mes,
        data: this.data,
        indgerVersion: this.indgerVersion
      });
    }
  }
};
</script>
